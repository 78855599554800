.sp-slider {
  width: 100%;
  height: 50px;
  background: #fefaae;
  overflow: hidden;
}

.sp-slider .range-slider__thumb {
  width: 15px;
  height: 25px;
  border-radius: 3px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E")
    #fff;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.sp-slider .range-slider__range {
  background: transparent;
  border: 2px solid #fccd70;
  box-sizing: border-box;
  box-shadow: 0 0 0 9999px #9da2b9;
}
